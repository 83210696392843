import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { DeepPickPath } from 'ts-deep-pick';

import defaultLanguage from './../../../assets/i18n/en-au.json';
import { BehaviorSubject } from 'rxjs';
import memoize from 'lodash/memoize';
import { StorageService } from './storage.service';
import { environment } from 'environments/environment';
import { BrowserLogger } from '@class/core/browser-logger';

const AVAILABLE_LANGUAGES_CACHE_KEY = 'available_languages';

interface AvailableLanguagesCache {
  data: LanguagePayload[];
  version: string;
}

type PickNested<T, K extends string> = K extends `${infer F}.${infer R}`
  ? F extends keyof T
    ? PickNested<T[F], R>
    : never
  : K extends keyof T
    ? T[K]
    : never;

export interface LanguagePayload {
  id: number;
  code: string;
  name: string;
  english_name: string;
}

export type Lang = typeof defaultLanguage;

export type LangKeys = DeepPickPath<Lang>;

export type InstantFunction = <K extends string>(key: K) => K extends LangKeys ? PickNested<Lang, K> : K;

export interface Str {
  login: Lang['Login'];
  general: Lang['General'];
  user: Lang['User'];
  droplet: Lang['Droplet'];
  addDevice: Lang['AddDevice'];
  vpp: Lang['VirtualPowerPlant'];
  admin: Lang['Admin'];
  profile: Lang['ProfilePage'];
  unitPage: Lang['UnitPage'];
}

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  public str = {} as Str;
  private readonly defaultLangCode = 'en-au';

  public transLanguages = {
    languages: [] as LanguagePayload[],
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    ready: new BehaviorSubject(false),
    error: {},
  };

  constructor(
    private translate: TranslateService,
    private api: ApiWrapper,
    private _storageService: StorageService,
  ) {
    this.setDefault(this.defaultLangCode);

    this.translate.get('Login').subscribe((str: Lang['Login']) => {
      this.str.login = this.instantRecursive(str);
    });
    this.translate.get('General').subscribe((str: Lang['General']) => {
      this.str.general = this.instantRecursive(str);
    });
    this.translate.get('User').subscribe((str: Lang['User']) => {
      this.str.user = this.instantRecursive(str);
    });
    this.translate.get('Droplet').subscribe((str: Lang['Droplet']) => {
      this.str.droplet = this.instantRecursive(str);
    });
    this.translate.get('AddDevice').subscribe((str: Lang['AddDevice']) => {
      this.str.addDevice = this.instantRecursive(str);
    });
    this.translate.get('VirtualPowerPlant').subscribe((str: Lang['VirtualPowerPlant']) => {
      this.str.vpp = this.instantRecursive(str);
    });
    this.translate.get('Admin').subscribe((str: Lang['Admin']) => {
      this.str.admin = this.instantRecursive(str);
    });
    this.translate.get('ProfilePage').subscribe((str: Lang['ProfilePage']) => {
      this.str.profile = this.instantRecursive(str);
    });
    this.translate.get('UnitPage').subscribe((str: Lang['UnitPage']) => {
      this.str.unitPage = this.instantRecursive(str);
    });
  }

  public instant: InstantFunction = memoize(<K extends string>(key: K) => {
    return this.instantRecursive(this.translate.instant(key));
  });

  /**
   * The ngx-translate-messageformat-compiler package causes `instant(...)` calls to return functions
   * instead of strings. This will call them to get the `string` values so that the `instant` method
   * operates the same as before.
   */
  private instantRecursive(obj: unknown) {
    if (obj == null) {
      return null;
    }

    switch (typeof obj) {
      case 'function':
        if (obj.length > 0) {
          return obj;
        }

        return obj();
      case 'object':
        return Object.entries(obj).reduce(
          (result, [key, value]) => ({
            ...result,
            [key]: this.instantRecursive(value),
          }),
          {},
        );
      case 'string':
      default:
        return obj;
    }
  }

  public async getAvailableLanguages(): Promise<{ data: LanguagePayload[] }> {
    const cachedLanguages =
      await this._storageService.getFromLocalStorage<AvailableLanguagesCache>(AVAILABLE_LANGUAGES_CACHE_KEY);

    const cachedLanguagesIsValid = cachedLanguages && cachedLanguages.version === environment.version;

    BrowserLogger.log('getAvailableLanguages', { cachedLanguages, cachedLanguagesIsValid });

    if (!cachedLanguagesIsValid) {
      const res = (await this.api.handleRequest(
        AvailableAPI.SWITCHDIN,
        '/api/v1/languages/',
        RequestMethod.GET,
        UseHeaderType.AUTHORIZED_SWDIN,
      )) as { data: LanguagePayload[] };

      this._storageService.setLocalStorageItem(AVAILABLE_LANGUAGES_CACHE_KEY, {
        ...res,
        version: environment.version,
      });

      return res;
    }

    return cachedLanguages;
  }

  public setDefault(lang: string) {
    this.translate.setTranslation(lang, defaultLanguage, true);
    this.translate.setDefaultLang(lang);
  }

  public getCurrentLanguage() {
    return this.translate.currentLang;
  }

  public getLanguages() {
    this.getAvailableLanguages()
      .then(({ data }) => {
        this.transLanguages.apiCall = true;
        this.transLanguages.languages = data;
        this.transLanguages.dataExist = true;
        this.transLanguages.ready.next(true);
      })
      .catch((err) => {
        this.transLanguages.apiCallFailed = true;
        this.transLanguages.error = err;
      });
  }

  private setLanguage(languageCode: string) {
    this.translate.use(languageCode);
    this.translate.setDefaultLang(languageCode);
  }

  private getLanguageCodeFromId(languageId: number): string {
    let languageCode = this.defaultLangCode;
    if (this.transLanguages.dataExist) {
      const inputLang = this.transLanguages.languages.find((lang) => lang.id == languageId);
      if (inputLang) {
        languageCode = inputLang.code;
      }
    }
    return languageCode;
  }
  private checkLanguageCodeExistInTheLanguages(langCode: string): boolean {
    let langCodeExist = false;
    if (this.transLanguages.dataExist) {
      const inputLang = this.transLanguages.languages.find((lang) => lang.code === langCode);
      if (inputLang) {
        langCodeExist = true;
      }
    }
    return langCodeExist;
  }

  public getBrowserLang() {
    return this.translate.getBrowserLang();
  }

  public getBrowserCultureLang() {
    return this.translate.getBrowserCultureLang();
  }

  public setUserLanguage(languageId: number) {
    let languageCode: string = '';

    if (languageId) {
      languageCode = this.getLanguageCodeFromId(languageId);
    } else if (this.translate.getBrowserCultureLang()) {
      languageCode = this.translate.getBrowserCultureLang()!.toLowerCase();
      if (!this.checkLanguageCodeExistInTheLanguages(languageCode)) {
        languageCode = this.defaultLangCode;
      }
    }

    this.setLanguage(languageCode);
  }
}
