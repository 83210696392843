import { raaTheme } from './raa-theme';
import { eguanaTheme } from './eguana-theme';
import { evoPowerTheme } from './evo-power-theme';
import { redEarthTheme } from './red-earth-theme';
import { simplyEnergyTheme } from './simply-energy';
import { sonnenTheme } from './sonnen-theme';
import { switchdinTheme } from './switchdin-theme';
import { ThemeInterface } from './theme-interface';
import { yurikaTheme } from './yurika-theme';
import { ausgridTheme } from './ausgrid-theme';
import { asfgJacanaTheme } from './asfg-jacana-theme';
import { powerPlusEnergyTheme } from './power-plus-energy-theme';
import { symphonyTheme } from './symphony-theme';
import { gmEnergyTheme } from './gm-energy';

const themes: ThemeInterface[] = [
  switchdinTheme,
  simplyEnergyTheme,
  eguanaTheme,
  yurikaTheme,
  redEarthTheme,
  sonnenTheme,
  raaTheme,
  evoPowerTheme,
  ausgridTheme,
  asfgJacanaTheme,
  powerPlusEnergyTheme,
  symphonyTheme,
  gmEnergyTheme,
];

export default themes;
