import { DeepPick } from 'ts-deep-pick';
import { Environment } from './environment.type';

export default {
  appName: import.meta.env.NG_APP_NAME,
  version: import.meta.env.NG_APP_VERSION,
  production: false,
  serviceWorker: false,
  weatherAPIURL: 'https://api.openweathermap.org',
  dropletApiUrl: 'http://192.168.44.1:88/api',
  eguanaReferrerUrl: 'http://localhost:9000/',
  testsRunning: false,
  services: {
    honeycomb: {
      endpoint: 'https://otel-collector-public.switchd.in:4319',
    },
    sentry: {
      dsn: 'https://455ba45cb4595b83644599c7447b0910@o4506311827259392.ingest.sentry.io/4506311829094400',
      dist: import.meta.env.NG_APP_SENTRY_DIST,
    },
  },
} satisfies DeepPick<
  Environment,
  | 'version'
  | 'appName'
  | 'serviceWorker'
  | 'production'
  | 'weatherAPIURL'
  | 'dropletApiUrl'
  | 'eguanaReferrerUrl'
  | 'testsRunning'
  | 'services.honeycomb'
  | 'services.sentry'
>;
