import { BooleanStrings } from '@class/commons/enums';
import { MeritControlValueType, VppSchedule } from './vpp-control-group-schedule.model';
import { VppDemandResponse } from './vpp-demand-response.model';
import { VppDemandTypes } from './vpp-demand-type-mode.model';
import { VppFcasMarket } from './vpp-fcas-markets.model';
import { VppUnitDeviceTypes } from './vpp-unit.model';
import { DateTime } from 'luxon';

const formatDateTime = (epochSeconds: number | string, format: string) => {
  if (typeof epochSeconds === 'string') {
    return DateTime.fromISO(epochSeconds).toFormat(format);
  }
  return DateTime.fromSeconds(epochSeconds).toFormat(format);
};

const fromNow = (epochSeconds: number | string) => {
  if (typeof epochSeconds === 'string') {
    return DateTime.fromISO(epochSeconds).toRelative();
  }
  return DateTime.fromSeconds(epochSeconds).toRelative();
};

export enum VppDispatchAction {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export enum VppDispatchType {
  AUTO = 'Auto',
  MANUAL = 'Manual',
  UNSCHEDULED = 'Unscheduled',
  UNASSOCIATED = 'Unassociated',
}

export enum VppOperationDispatchModalType {
  GRAPH_DISPATCH = 0,
  NOW_DISPATCH = 1,
  CANCEL = 2,
}

export const VPP_TABLE_PAGE_SIZE_OPTIONS = [10, 25, 50];
export interface VppOperationDispatchRow extends VppDemandResponse {
  available: number;
  capacity: string;
  dispatch: number;
}

export interface VppDispatchHistoryGetPayload {
  action: VppDispatchAction;
  available: number;
  control_value: BooleanStrings | number | string;
  control_type: MeritControlValueType;
  control_priority: number;
  control_description: string;
  demand_type: VppDemandTypes;
  dispatch: number;
  dispatch_type: VppDispatchType;
  device: string;
  end_time: number; //epoch seconds
  group: string; //control group uuid
  request_time: Date; //2019-09-27T15:49:49+10:00
  start_time: number; //epoch seconds
  vpp: string; //vpp uuid
  user_fullname?: string;
  schedule: VppSchedule;

  //FCAS
  markets?: string[];

  //DRM
  order?: number; //merit order number
  policies?: string[]; //policies uuid
}

export interface VppDispatchHistoryDisplay {
  available: number;
  dispatch: number;
  dispatchType: VppDispatchType;
  deviceName: string;
  startTime: string; //DD-MM-YYYY HH:mm:ss
  startTimeEpochSec: number;
  endTime: string; //DD-MM-YYYY HH:mm:ss
  endTimeEpochSec: number;
  startTimeFromNow: string;
  requestTime: string; //DD-MM-YYYY HH:mm:ss
  requestTimeFromNow: string;
  requestDateTime: Date;
  action: VppDispatchAction;
  controlValue: BooleanStrings | number | string;
  controlType: MeritControlValueType;
  controlPriority: number;
  controlDescription: string;
  demandType: VppDemandTypes;
  group: string; //control group uuid
  vpp: string; //vpp uuid
  unitName: string;
  schedule: VppSchedule;
  userFullname: string;

  //FCAS
  markets?: string[];

  //DRM
  order?: number; //merit order uuid
  policies?: string[]; //policies uuid
}
export interface VppDispatchModalData {
  modalType: VppOperationDispatchModalType;
  action: VppDispatchAction;
  controlValue: BooleanStrings | number | string;
  controlType: MeritControlValueType;
  controlPriority: number;
  demandType: VppDemandTypes;
  dispatchType: VppDispatchType;
  endTime: number; //epoch seconds
  controlGroupName: string; //control group uuid
  startTime: number; //epoch seconds
  vpp: string; //vpp uuid
  userFullname?: string;
  deviceClass: VppUnitDeviceTypes;
  description: string;
  requestTime?: string;
  //FCAS
  markets?: VppFcasMarket[];

  //DRM
  meritOrder?: number; //merit order uuid
  policies?: string; //policies uuid

  // see vpp-operation-schedule-modal-facade.service.ts for explanation why we using this
  showEndTimeIonDateTimePicker?: boolean;
}

export interface VppDispatchHistoryPostPayload {
  action: VppDispatchAction;
  control_value: BooleanStrings | number | string;
  control_type: MeritControlValueType;
  control_priority: number;
  demand_type: VppDemandTypes;
  dispatch_type: VppDispatchType;
  end_time: number; //epoch seconds
  group: string; //control group uuid
  request_time: string; //2019-09-27T15:49:49+10:00
  start_time: number; //epoch seconds
  vpp: string; //vpp uuid
  user_fullname?: string;

  //FCAS
  markets?: string[];

  //DRM
  order?: number; //merit order uuid
  policies?: string[]; //policies uuid
}

export const adaptVppDispatchHistGetPayloadToVppDispatchHistDisplay = (
  vppDispatchGetPayload: VppDispatchHistoryGetPayload,
): VppDispatchHistoryDisplay => {
  const {
    action,
    available,
    control_value,
    control_type,
    control_priority,
    control_description,
    demand_type,
    dispatch,
    dispatch_type,
    end_time,
    group,
    request_time,
    start_time,
    vpp,
    user_fullname,
    markets,
    order,
    policies,
    device,
    schedule,
  } = vppDispatchGetPayload;

  const endTimeFromNow = DateTime.fromSeconds(end_time).toRelative();
  const splitPattern = ' - ';
  const unitName = device.split(splitPattern)[0];
  const vppDispatchHistoryDisplay = {
    available: available,
    dispatch: dispatch,
    dispatchType: dispatch_type,
    startTimeEpochSec: start_time,
    startTime: formatDateTime(start_time, 'dd-MM-yyyy HH:mm:ss'),
    endTimeEpochSec: end_time,
    endTime: formatDateTime(end_time, 'dd-MM-yyyy HH:mm:ss'),
    startTimeFromNow: fromNow(start_time),
    requestTime: formatDateTime(request_time as unknown as string, 'dd-MM-yyyy HH:mm:ss'),
    requestTimeFromNow: fromNow(request_time as unknown as string),
    requestDateTime: request_time,
    endTimeFromNow,
    action,
    controlValue: control_value,
    controlType: control_type,
    controlPriority: control_priority,
    controlDescription: control_description,
    demandType: demand_type,
    group,
    vpp, //vpp uuid
    schedule,
    deviceName: device,
    unitName,

    //FCAS
    markets,

    //DRM
    order, //merit order
    policies, //policies uuid

    userFullname: user_fullname,
  };
  return vppDispatchHistoryDisplay;
};

export const adaptVppDispatchHistDisplayToVppDispatchHistPostPayload = (
  vppDispatchGetPayload: VppDispatchHistoryDisplay,
): VppDispatchHistoryPostPayload => {
  const {
    action,
    controlValue,
    controlType,
    controlPriority,
    demandType,
    dispatchType,
    endTimeEpochSec,
    group,
    requestDateTime,
    startTimeEpochSec,
    vpp,
    markets,
    order,
    policies,
  } = vppDispatchGetPayload;

  const vppDispatchPostPayload: VppDispatchHistoryPostPayload = {
    action,
    control_value: controlValue,
    control_type: controlType,
    control_priority: controlPriority,
    demand_type: demandType,
    dispatch_type: dispatchType,
    end_time: endTimeEpochSec,
    group,
    request_time: requestDateTime.toISOString(),
    start_time: startTimeEpochSec,
    vpp,
    markets,
    order,
    policies,
  };
  return vppDispatchPostPayload;
};

export const adaptVppDispatchModalDataToPostPayload = (
  modalData: VppDispatchModalData,
): VppDispatchHistoryPostPayload => {
  const {
    action,
    controlValue,
    controlType,
    controlPriority,
    demandType,
    dispatchType,
    endTime,
    controlGroupName,
    requestTime,
    startTime,
    vpp,
    markets,
    meritOrder,
    policies,
  } = modalData;

  const vppDispatchPostPayload: VppDispatchHistoryPostPayload = {
    action,
    control_value: controlValue,
    control_type: controlType,
    control_priority: controlPriority,
    demand_type: demandType,
    dispatch_type: dispatchType,
    end_time: endTime,
    group: controlGroupName,
    request_time: requestTime,
    start_time: startTime,
    vpp,
    markets: markets?.map((market) => market.uuid) ?? [],
    order: meritOrder,
    policies: policies ? [policies] : null,
  };
  return vppDispatchPostPayload;
};
export const adaptVppDemandResponseToVppUnscheduledDispatchHistPostPayloads = (
  vppDemandResponse: VppDemandResponse,
): VppDispatchHistoryPostPayload => {
  const { demand_type, group, merit_order, control_type } = vppDemandResponse;
  const vppDispatchGetPayload: VppDispatchHistoryPostPayload = {
    action: null,
    control_value: control_type === MeritControlValueType.BOOLEAN ? BooleanStrings.TRUE : null,
    control_type: control_type,
    control_priority: null,
    demand_type,
    dispatch_type: VppDispatchType.UNSCHEDULED,
    end_time: null,
    group,
    markets: [],
    order: merit_order,
    request_time: null,
    start_time: null,
    vpp: null,
  };

  return vppDispatchGetPayload;
};

export const adaptVppDemandResponseToDispatchModalData = (
  response: VppDemandResponse,
  modalType: VppOperationDispatchModalType,
  startTime?: number,
  endTime?: number,
): VppDispatchModalData => {
  const { control_desc, demand_type, device_class, group, merit_order, policies_seq, markets, control_type } = response;

  const modalData: VppDispatchModalData = {
    modalType,
    description: control_desc,
    action: null,

    // setting the value default if control_type is boolean to true
    controlValue: control_type === MeritControlValueType.BOOLEAN ? BooleanStrings.TRUE : null,
    controlType: control_type,
    controlPriority: null,
    demandType: demand_type,
    dispatchType: VppDispatchType.UNSCHEDULED,
    endTime, //epoch seconds
    controlGroupName: group, //control group uuid
    startTime, //epoch seconds
    vpp: null, //vpp uuid
    userFullname: null,
    markets,
    meritOrder: merit_order,
    policies: policies_seq[0] ? policies_seq[0]?.toString() : null,
    deviceClass: device_class as VppUnitDeviceTypes,
  };
  return modalData;
};

export const adaptVppDispatchHistoryDisplayToModalData = (
  dispatch: VppDispatchHistoryDisplay,
  modalType: VppOperationDispatchModalType,
): VppDispatchModalData => {
  const { demandType, endTimeEpochSec, group, startTimeEpochSec, order, policies, controlType, controlPriority } =
    dispatch;

  const modalData: VppDispatchModalData = {
    modalType,
    description: null,
    action: null,
    controlValue: null,
    controlType,
    controlPriority,
    demandType,
    dispatchType: VppDispatchType.UNSCHEDULED,
    endTime: endTimeEpochSec, //epoch seconds
    controlGroupName: group, //control group uuid
    startTime: startTimeEpochSec, //epoch seconds
    vpp: null, //vpp uuid
    userFullname: null,
    markets: null,
    meritOrder: order,
    policies: policies?.[0] ?? null,
    deviceClass: null,
  };
  return modalData;
};
